import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
//import bnrImg1 from "../../images/homecare images/nurse-helping-senior-man-768.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
//import servicesPic1 from "../../images/services/pic1.jpg";
import servicesPic1 from "../../images/homecare images/nurse-helping-senior-man-768.jpg";
import pdf from "../../images/icon/pdf.png";
import doc from "../../images/icon/doc.png";

class HomeCareDetailServices extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          <div className="banner-wraper">
            <div
              className="page-banner"
              style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
            >
              <div className="container">
                <div className="page-banner-entry text-center">
                  <h1>Service Details</h1>
                  <nav aria-label="breadcrumb" className="breadcrumb-row">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="index.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="feather feather-home"
                          >
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                          </svg>{" "}
                          Home
                        </a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Service Details
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <img className="pt-img1 animate-wave" src={waveBlue} alt="" />
              <img className="pt-img2 animate2" src={circleDots} alt="" />
              <img className="pt-img3 animate-rotate" src={plusBlue} alt="" />
            </div>
          </div>

          <section className="section-area section-sp1">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 mb-30">
                  <div className="ttr-media mb-30">
                    <img src={servicesPic1} className="rounded" alt="" />
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <h2 className="title mb-15">Home Care Services</h2>
                      <p className="mb-0">
                        Home care makes aging in place possible for millions of
                        seniors every year. Instead of moving to an assisted
                        living facility or a nursing home, seniors can continue
                        living where they feel happiest and most comfortable —
                        their home.
                      </p>
                      <br />
                      <p className="mb-0">
                        No one understands the importance of senior care like we
                        do at LeanOn Care®. As one of the country's most trusted
                        home care agencies in the country, we make senior care
                        possible for older adults across the U.S. Our in-home
                        care services for the elderly make daily living safer
                        and more enjoyable, allowing seniors to spend their
                        golden years in the comfort of home.
                      </p>
                      <br />
                      <p className="mb-0">
                        Our elder care offers valuable support to families.
                        LeanOn Care makes it easier to care for aging loved
                        ones, helping families to spend more quality time
                        together. At the same time, we provide much-needed
                        respite for family caregivers, reducing their risk of
                        caregiver burnout.
                      </p>
                    </div>

                    {/*<div className="row align-items-center">
											<div className="col-md-6 mb-30">
												<ul className="list-check-squer mb-0">
													<li>Then along come two they</li>
													<li>That’s just a little bit more than</li>
													<li>Standard dummy text ever since</li>
													<li>Simply dummy text of the printing</li>
													<li>Make a type specimen book</li>
												</ul>
											</div>
											<div className="col-md-6 mb-30">
												<div className="skillbar-box mb-30">
													<h6 className="title">Advanced Technology</h6>
													<div className="skillbar appear">
														<p className="skillbar-bar" style={{width: "96%"}}></p>
														<span className="skill-bar-percent">96%</span>
													</div>
												</div>
												<div className="skillbar-box mb-30">
													<h6 className="title">Certified Engineers</h6>
													<div className="skillbar appear">
														<p className="skillbar-bar" style={{width: "79%"}}></p>
														<span className="skill-bar-percent">79%</span>
													</div>
												</div>
												<div className="skillbar-box mb-0">
													<h6 className="title">6 years Experience</h6>
													<div className="skillbar appear">
														<p className="skillbar-bar" style={{width: "75%"}}></p>
														<span className="skill-bar-percent">75%</span>
													</div>
												</div>
											</div>
										</div>*/}
                    <div className="head-text mb-30">
                      <h4 className="title mb-10">Home Care for Seniors</h4>
                      <p className="mb-0">
                        Choosing the right type of home care will ensure your
                        loved one's comfort and improve their quality of life.
                        But if you're unfamiliar with the different types of
                        home care, deciding between them can be confusing.
                      </p>
                      <br />
                      <p className="mb-0">
                        The good news? Selecting the right home care service is
                        simpler than you might expect. Here are some options
                        offered:
                      </p>
                      <br />
                    </div>
                    <div className="head-text mb-30">
                      <h4 className="title mb-10">
                        Senior Care That Is Personalized
                      </h4>
                      <p className="mb-0">
                        At LeanOn Care, our goal is simple. We want to make
                        senior care as comfortable and enjoyable for loved ones
                        who want to age in place. At the same time, we want to
                        make home care services for the elderly as easy and
                        stress-free as possible for families.
                      </p>
                      <br />
                      <p className="mb-0">
                        Our approach to senior care starts with a custom care
                        plan for every client. Your local LeanOn Care home care
                        agency will personalize a comprehensive care plan to
                        your loved one's unique needs and living conditions.
                        This way, we provide support exactly where it's needed.
                      </p>
                      <br />
                      <p className="mb-0">
                        When coordinating care, we work closely with families
                        each step of the way. Once care starts, we remain in
                        close contact with family members to keep them up to
                        date. As your loved one's needs evolve, we'll work with
                        you to make sure they continue receiving the care they
                        need.
                      </p>
                      <br />
                    </div>
                    <div className="head-text mb-30">
                      <h4 className="title mb-10">Home Care Assistance</h4>
                      <p className="mb-0">
                        With LeanOn Care, it's easy to get started with senior
                        care services.
                      </p>
                      <br />
                      <p className="mb-0">
                        Your local LeanOn Care agency will schedule a free
                        consultation to begin the process. We'll learn about
                        your loved one's needs, living conditions, and personal
                        preferences. We'll use this information to build a
                        framework for your loved one's care plan.
                      </p>
                      <br />
                      <p className="mb-0">
                        As we develop your loved one's plan, we consider a wide
                        range of factors. Our home care assistance
                        recommendations include your loved one's personal
                        routine, while also developing a schedule that fits
                        comfortably within your budget. Our home care agencies
                        also work with doctors, nurses, and other health care
                        providers to ensure our care plans align with medical
                        directives.
                      </p>
                      <br />
                      <p className="mb-0">
                        The final step is to match your loved one with a
                        caregiver (or caregivers). At LeanOn Care, we pride
                        ourselves on the compassion, dedication, and experience
                        of our home care providers. We know how crucial the
                        relationship between caregivers and seniors can be.
                        Because of this, we strive to find the perfect fit for
                        every client.
                      </p>
                      <br />
                    </div>
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <h4 className="title mb-10">
                        Home Care Questions & Answers
                      </h4>
                      {/*<p className="mb-0">Standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>*/}
                    </div>
                    <Accordion
                      defaultActiveKey="0"
                      className="accordion ttr-accordion1"
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Why Do I need Home Care
                        </Accordion.Header>
                        <Accordion.Body>
                          <p className="mb-0">
                            For many families, home care services provide a
                            beneficial choice—a safe and affordable solution
                            that supports the family, while allowing your loved
                            ones to stay in the comfort of their own homes and
                            communities.
                          </p>
                          <br />
                          <p className="mb-0">
                            Home care provides compassion and help for seniors
                            who need assistance with daily activities. Levels of
                            care vary by need and can span from weekly meal prep
                            to incontinence help.
                          </p>
                          <br />
                          <p className="mb-0">
                            Home caregivers understand the nuances of senior
                            care, so they can be attentive and proactive with
                            determining the proper care needed for each senior.
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          Is In-Home Care a Better Option Than Nursing Home or
                          Assisted Living Facility?
                        </Accordion.Header>
                        <Accordion.Body>
                          <p className="mb-0">
                            In-Home care is a more private option that allows
                            your loved one to remain in their own home. This is
                            often less disruptive and more engaging, making it
                            the first choice for many families. Couple that with
                            the rising costs of nursing home facilities, and
                            it's understandable why in-home care has become the
                            preferred option for many families.
                          </p>
                          <br />
                          <p className="mb-0">
                            Before deciding between the benefits of in-home care
                            vs. a nursing home, there are several things you'll
                            want to think about:
                          </p>
                          <br />
                          <ul className="" style={{ listStyleType: "circle" }}>
                            <li>
                              Ask your loved one about how important it is for
                              them to continue living at home.
                            </li>
                            <li>
                              Speak with your loved one's physician about what
                              level of caregiving is needed.
                            </li>
                            <li>
                              Determine whether your loved one requires
                              specialized caregiving, like Alzheimer's care.
                            </li>
                            <li>
                              Calculate your budget for caregiving services or
                              residence in a care facility.
                            </li>
                            <li>
                              Get detailed information from local in-home care
                              companies and assisted living centers.
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          What is the Difference Between Home Care and Home
                          Health Care?
                        </Accordion.Header>
                        <Accordion.Body>
                          <p className="mb-0">
                            When someone thinks about home care, they assume
                            home care and home health care are the same, but
                            that is not the case. This can make it confusing for
                            families searching for the right type of care – and
                            the right care providers – for their loved one..
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>
                          How Do I Start Home Care Services With LeanON Care?
                        </Accordion.Header>
                        <Accordion.Body>
                          <p className="mb-0">
                            Contact LeanOn care office by felling the
                            <Link to="/booking">
                              {" "}
                              booking form on this website{" "}
                            </Link>{" "}
                            or call{" "}
                            <a href="tel:+12403600780">+1 240 360 0780</a> with
                            you senior home care questions. We will have our
                            staff come to your home and conduct a free, no
                            obligation home care assessment. We will then, at
                            your direction, identify caregivers who have the
                            skills, personality and availability to assist you.
                            You will then be able to meet the caregiver, and if
                            you approve, they will begin working for you.
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>
                          What is a Free Home Care Assessment by LeanOn Care?
                        </Accordion.Header>
                        <Accordion.Body>
                          <p className="mb-0">
                            At LeanOn Care, a complete home care assessment is
                            an essential step in coordinating care for an
                            elderly loved one. A free in-home care consultation
                            with a LeanOn Care coordinator is one of the best
                            ways to learn about personal care services provided
                            by our compassionate, professional caregivers.
                          </p>
                          <p className="mb-0">
                            Before we begin a relationship with any family, we
                            meet and discuss your care needs. This enables us to
                            carefully craft and tailor our home care services to
                            your family’s challenges and needs.
                          </p>
                          <p className="mb-0">
                            Our care coordinators offer no-cost, no-obligation
                            consultations designed to answer your questions and
                            provide expert guidance to help you navigate the
                            senior care decision-making process.
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="5">
                        <Accordion.Header>
                          Can I Choose My Caregiver?
                        </Accordion.Header>
                        <Accordion.Body>
                          <p className="mb-0">
                            After you receive your home care assessment, we will
                            place you in contact with your local LeanOn Care
                            location. The local director will review your home
                            care assessment, meet with you and then work with
                            you to choose the best caregiver. This specialized
                            program can play a big part in making your loved
                            one's care at home a success.
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
                <div className="col-lg-4">
                  <aside className="sticky-top pb-1">
                    <div className="widget">
                      <ul className="service-menu">
                        <li className="active">
                          <Link to="/companion-care-service-detail">
                            <span>Companion Care</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/personal-care-service-detail">
                            <span>Personal Care</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/palliative-care-service-detail">
                            <span>Palliative Care</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/respite-care-service-detail">
                            <span>Respite Care</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/alzheimer-care-service-detail">
                            <span>Alzheimer's Care</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/dementia-care-service-detail">
                            <span>Dementia Care</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/transitional-care-service-detail">
                            <span>Transitional Care</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/social-care-service-detail">
                            <span>Social Care</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/end-of-life-care-service-detail">
                            <span>End of Life Care</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="widget">
                      <div className="brochure-bx">
                        <h5 className="title-head">Download</h5>
                        <Link to="#" className="download-link">
                          <img src={pdf} alt="" />
                          <h5 className="title">Download our Brochures</h5>
                          <span>Download</span>
                        </Link>
                        <Link to="#" class="download-link">
                          <img src={doc} alt="" />
                          <h5 className="title">Our Company Details</h5>
                          <span>Download</span>
                        </Link>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default HomeCareDetailServices;
