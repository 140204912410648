import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Import Images
import aboutThumb1 from '../../images/homecare images/bowling.jpg';
import aboutThumb2 from '../../images/homecare images/hello.jfif';
import aboutThumb3 from '../../images/homecare images/IMG-20230307-WA0001.jpg';
import aboutThumb4 from '../../images/homecare images/ease on down.jfif';
import aboutThumb5 from '../../images/homecare images/adult.jfif';
import aboutThumb6 from '../../images/homecare images/glasses.jfif';
import aboutThumb7 from '../../images/homecare images/Heart pic.jpg';
import aboutThumb8 from '../../images/homecare images/senior-home-care-homepage-desktop.jpg';
import ptImg1 from '../../images/shap/wave-orange.png';
import ptImg2 from '../../images/shap/circle-small-blue.png';
import ptImg4 from '../../images/shap/square-dots-orange.png';
import ptImg5 from '../../images/shap/square-blue.png';
import iconImg from '../../images/icon/homeicon2.png';
import iconImg1 from '../../images/icon/campanionicon1.png';
import iconImg2 from '../../images/icon/palliativeicon1.png';
import iconImg3 from '../../images/icon/alzhemiersicon1.png';
import iconImg4 from '../../images/icon/dementiaicon1.png';
import iconImg5 from '../../images/icon/lifelineicon1.png';

class aboutSection extends Component{
	render(){
		return(
			<>
				<section className="section-sp1 about-area">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6 mb-30">
								<div className="about-thumb-area">
									<ul>
										<li><img className="about-thumb1" src={aboutThumb1} alt=""/></li>
										<li><img className="about-thumb2" src={aboutThumb2} alt=""/></li>
										<li><img className="about-thumb3" src={aboutThumb3} alt=""/></li>
										<li><img className="about-thumb3" src={aboutThumb4} alt=""/></li>
									</ul>
									<ul>
										<li><img className="about-thumb1" src={aboutThumb5} alt=""/></li>
										<li><img className="about-thumb2" src={aboutThumb6} alt=""/></li>
										<li><img className="about-thumb3" src={aboutThumb7} alt=""/></li>
										<li><img className="about-thumb3" src={aboutThumb8 } alt=""/></li>
									</ul>
								</div>
							</div>
							<div className="col-lg-6 mb-30">
								<div className="heading-bx">
									<h6 className="title-ext text-secondary">About Us</h6>
									<h5 className="footer-title" style={{fontSize: "1.2rem"}}>Our Duty</h5>
									<p>At LeanOn Care, we provide safe, reliable and compassionate care and support to individuals of need in and within communities of the Common Wealth state of Virginia.  We advocate in the best interest for those we serve and protect their human rights in all aspects as we  accompany them daily in living the best quality of life according to their desires.</p>
									<h5 className="footer-title" style={{fontSize: "1.2rem"}}>Our Mission</h5>
									<p>Our mission is to provide excellent services that embrace a holistic approach to individuals with intellectual and developmental disabilities.  By offering person-centered planning, community integration, inclusion, and an environment to discover and fulfill their greatest potential.</p>
									<h5 className="footer-title" style={{fontSize: "1.2rem"}}>Our Vision</h5>
									<p>To develop a culture of inclusivity where people with developmental and intellectual disabilities will have access to advocacy, resources, and the skills needed to be empowered to live on their terms.</p>
									{/*<p>We provide the special tips and advice’s of health care treatment and high level of best technology involve in the our hospital.</p>*/}
								</div>
								{/*<div className="row">
									<h6 className="title-ext text-secondary">Our Commitments</h6>
									<div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
										<div className="feature-container feature-bx1 feature1">
											<div className="icon-md">
												<span className="icon-cell">
													<img className="about-thumb1" src={iconImg} alt=""/>
												</span> 
											</div>
											<div className="icon-content">
												<h4 className="ttr-title">Home Care</h4>
											</div>
										</div>
									</div>
									<div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
										<div className="feature-container feature-bx1 feature2">
											<div className="icon-md">
												<span className="icon-cell">
													<img className="about-thumb1" src={iconImg1} alt=""/>
												</span> 
											</div>
											<div className="icon-content">
												<h4 className="ttr-title">Companion Care</h4>
											</div>
										</div>
									</div>
									<div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
										<div className="feature-container feature-bx1 feature3">
											<div className="icon-md">
												<span className="icon-cell">
													<img className="about-thumb1" src={iconImg2} alt=""/>
												</span> 
											</div>
											<div className="icon-content">
												<h4 className="ttr-title">Palliative Care</h4>
											</div>
										</div>
									</div>
									<div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
										<div className="feature-container feature-bx1 feature4">
											<div className="icon-md">
												<span className="icon-cell">
													<img className="about-thumb1" src={iconImg3} alt=""/>
												</span> 
											</div>
											<div className="icon-content">
												<h4 className="ttr-title">Alzheimer's Care</h4>
											</div>
										</div>
									</div>
									<div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
										<div className="feature-container feature-bx1 feature1">
											<div className="icon-md">
												<span className="icon-cell">
													<img className="about-thumb1" src={iconImg4} alt=""/>
												</span> 
											</div>
											<div className="icon-content">
												<h4 className="ttr-title">Dementia Care</h4>
											</div>
										</div>
									</div>
									<div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
										<div className="feature-container feature-bx1 feature2">
											<div className="icon-md">
												<span className="icon-cell">
													<img className="about-thumb1" src={iconImg5} alt=""/>
												</span> 
											</div>
											<div className="icon-content">
												<h4 className="ttr-title">End of Life Care</h4>
											</div>
										</div>
									</div>
								</div>*/}
								<Link to="/about-us" className="btn btn-primary shadow">Read More</Link>
							</div>
						</div>
					</div>{/*
					<img className="pt-img1 animate-wave" src={ptImg1} alt=""/>
					<img className="pt-img2 animate2" src={ptImg2} alt=""/>
					<img className="pt-img3 animate-rotate" src={ptImg5} alt=""/>
					<img className="pt-img4 animate-wave" src={ptImg4} alt=""/>
							<img className="pt-img5 animate2" src={ptImg5} alt=""/>*/}
				</section>
				
			</>
		);
	}
}

export default aboutSection;