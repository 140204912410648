import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

// Import Images
import testShape from "../../images/testimonials/shape.png";
import testPic6 from "../../images/testimonials/pic6.jpg";

class testimonialSection extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <>
        <section className="section-area section-sp3 testimonial-wraper">
          <div className="container">
            <div className="heading-bx text-center">
              <h6 className="title-ext text-secondary">Testimonial</h6>
              <h2 className="title m-b0">See What Are Carers Are Saying</h2>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6 text-center">
                <div className="thumb-wraper">
                  <img className="bg-img" src={testShape} alt="" />
                  <ul>
                    <li data-member="1">
                      <Link to="#">{/*<img src={testPic1} alt="" />*/}</Link>
                    </li>
                    <li data-member="2">
                      <Link to="#">{/*<img src={testPic2} alt="" />*/}</Link>
                    </li>
                    <li data-member="3">
                      <Link to="#">{/*<img src={testPic3} alt="" />*/}</Link>
                    </li>
                    <li data-member="4">
                      <Link to="#"></Link>
                    </li>
                    <li data-member="5">
                      <Link to="#"></Link>
                    </li>
                    <li data-member="6">
                      <Link to="#"></Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <Slider {...settings} className="testimonial-slide">
                  <div className="slider-item">
                    <div className="testimonial-bx">
                      <div className="testimonial-content">
                        <p>
                          I really appreciate the care manager’s competent and
                          caring communication with the caregivers and the
                          facility and myself as a family member. That really
                          makes me feel confident that my mother is being well
                          taken care of and is a great help for me to make a
                          decision.
                        </p>
                      </div>
                      <div className="client-info">
                        <h5 className="name">Sarah (Daughter)</h5>
                      </div>
                      <div className="quote-icon">
                        <i className="fas fa-quote-left"></i>
                      </div>
                    </div>
                  </div>
                  <div className="slider-item">
                    <div className="testimonial-bx">
                      <div className="testimonial-content">
                        <p>
                          Calling your office, nearly 1 years ago, was the best
                          thing I could have done. I met with Jennifer with whom
                          over the years has been a life saver for me. She
                          helped my Mom but she always was there for me too. She
                          listened and listened and listened. She is one of the
                          most caring people I have ever met!
                        </p>
                      </div>
                      <div className="client-info">
                        <h5 className="name">Diane (Daughter)</h5>
                      </div>
                      <div className="quote-icon">
                        <i className="fas fa-quote-left"></i>
                      </div>
                    </div>
                  </div>
                  <div className="slider-item">
                    <div className="testimonial-bx">
                      <div className="testimonial-content">
                        <p>
                          I just wanted to take a moment to tell you how pleased
                          we were with the service we received from Family &
                          Nursing Care but more importantly, with the care that
                          my dad received from his primary Caregiver. It meant
                          so much to us that we had a Caregiver that not only
                          was a “giver of care” but also a “giver of love and
                          compassion.” For this we are extremely grateful and
                          wanted to share our appreciation.”Lorem ipsum dolor
                          sit amet, consectetur adipiscing elit, sed do eiusmod
                          tempor incididunt ut labore et dolore magna aliqua.
                          Quis ipsum suspendisse ultrices gravida. Risus commodo
                          viverra maecena ssuspendisse ultrices gravida.
                        </p>
                      </div>
                      <div className="client-info">
                        <h5 className="name">Markus (Son)</h5>
                      </div>
                      <div className="quote-icon">
                        <i className="fas fa-quote-left"></i>
                      </div>
                    </div>
                  </div>
                  <div className="slider-item">
                    <div className="testimonial-bx">
                      <div className="testimonial-content">
                        <p>
                          Our Caregiver took warm and wonderful care of my
                          mother over many months even as mom’s care needs were
                          changing. The day my mother died, even though she was
                          off duty, she insisted on cutting her time off short
                          and came to bathe my mother before the arrival of the
                          funeral home attendants. She was a key member of the
                          support team our family crafted for my mom. Our family
                          is grateful that Family & Nursing Care brought her
                          into the circle of care that surrounded my mother as
                          she died.
                        </p>
                      </div>
                      <div className="client-info">
                        <h5 className="name">Ronald (Son)</h5>
                      </div>
                      <div className="quote-icon">
                        <i className="fas fa-quote-left"></i>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default testimonialSection;
