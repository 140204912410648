import React from 'react'

export function Confirm(props) {
    

    return (
        <div className='popup'>
            <img src='./images/tick.png' alt='' />
            <h4>Thank You</h4>
            <p>Your Message has been sent</p>
            <button type="button">OK</button>
        </div>
    )
}

