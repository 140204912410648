import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
//import bnrImg1 from "../../images/homecare images/nurse-helping-senior-man-768.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
//import servicesPic1 from "../../images/services/pic1.jpg";
import servicesPic1 from "../../images/companioncare images/Companion-Care-for-Seniors-in-Naperville-IL.jpg";
import pdf from "../../images/icon/pdf.png";
import doc from "../../images/icon/doc.png";

class HomeCareDetailServices extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          <div className="banner-wraper">
            <div
              className="page-banner"
              style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
            >
              <div className="container">
                <div className="page-banner-entry text-center">
                  <h1>Service Details</h1>
                  <nav aria-label="breadcrumb" className="breadcrumb-row">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="index.html">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="feather feather-home"
                          >
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                          </svg>{" "}
                          Home
                        </a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Service Details
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <img className="pt-img1 animate-wave" src={waveBlue} alt="" />
              <img className="pt-img2 animate2" src={circleDots} alt="" />
              <img className="pt-img3 animate-rotate" src={plusBlue} alt="" />
            </div>
          </div>
          <section className="section-area section-sp1">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 mb-30">
                  <div className="ttr-media mb-30">
                    <img src={servicesPic1} className="rounded" alt="" />
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <h2 className="title mb-15">Companion Care Services</h2>
                      <p className="mb-0">
                        Have you recently noticed sudden, troubling swings in
                        your elderly loved one’s mood, temperament, and
                        well-being? Though unexpected behavioral changes are
                        common in seniors, these abrupt fluctuations can
                        sometimes result from the adverse effects of loneliness,
                        isolation, or boredom.
                      </p>
                      <br />
                      <p className="mb-0">Companion care can help.</p>
                      <br />
                      <p className="mb-0">
                        Professional non-medical caregivers can boost your
                        senior’s attitude and mental state of mind—and benefit
                        you as well.
                      </p>
                      <p className="mb-0">
                        Companion caregiving is a popular service for seniors
                        and disabled adults who need socialization and
                        interaction but do not require advanced assistance.
                        Professional home care services help them age in place,
                        maintain their independence, and give you much-needed
                        confidence they are receiving safe, compassionate care.
                      </p>
                    </div>
                  </div>
                  {/*<div className="row align-items-center">
											<div className="col-md-6 mb-30">
												<ul className="list-check-squer mb-0">
													<li>Then along come two they</li>
													<li>That’s just a little bit more than</li>
													<li>Standard dummy text ever since</li>
													<li>Simply dummy text of the printing</li>
													<li>Make a type specimen book</li>
												</ul>
											</div>
											<div className="col-md-6 mb-30">
												<div className="skillbar-box mb-30">
													<h6 className="title">Advanced Technology</h6>
													<div className="skillbar appear">
														<p className="skillbar-bar" style={{width: "96%"}}></p>
														<span className="skill-bar-percent">96%</span>
													</div>
												</div>
												<div className="skillbar-box mb-30">
													<h6 className="title">Certified Engineers</h6>
													<div className="skillbar appear">
														<p className="skillbar-bar" style={{width: "79%"}}></p>
														<span className="skill-bar-percent">79%</span>
													</div>
												</div>
												<div className="skillbar-box mb-0">
													<h6 className="title">6 years Experience</h6>
													<div className="skillbar appear">
														<p className="skillbar-bar" style={{width: "75%"}}></p>
														<span className="skill-bar-percent">75%</span>
													</div>
												</div>
											</div>
										</div>*/}
                  <div className="head-text mb-30">
                    <h4 className="title mb-10">What Is Companion Care?</h4>
                    <p className="mb-0">
                      Companion care provides emotional and physical assistance
                      for seniors and disabled adults to help them feel secure
                      and content in the comfort of their homes. Socialization
                      and companionship can help decrease anxiety, depression,
                      and feelings of isolation.
                    </p>
                    <br />
                    <p className="mb-0">
                      A Visiting Angels’ senior care provider can improve the
                      quality of life for your loved one in many ways by acting
                      as a trusted friend during your loved one’s favorite
                      hobbies and activities, accompanying them to appointments
                      and social outings, and providing pleasant conversation.
                      These activities can serve as a lifeline for your loved
                      one, especially if they are secluded due to health or
                      mobility concerns or have no nearby friends or relatives.
                    </p>
                    <br />
                  </div>
                  <div className="head-text mb-30">
                    <h4 className="title mb-10">
                      How Can Social Isolation and Loneliness Affect Seniors?
                    </h4>
                    <p className="mb-0">
                      Reports show social isolation can dramatically increase
                      the risk of health complications and mortality in seniors.
                      The U.S. Centers for Disease Control and Prevention (CDC)
                      found that loneliness and social isolation are associated
                      with the following:
                    </p>
                    <br />
                    <ul className="" style={{ listStyleType: "circle" }}>
                      <li>A 50 percent increase in the risk of dementia</li>
                      <li>Higher rates of depression, anxiety, and suicide</li>
                      <li>
                        An increased risk of premature death, physical health
                        problems, and hospitalization
                      </li>
                    </ul>
                  </div>
                  <div className="head-text mb-30">
                    <p className="mb-0">
                      Seniors may be at a higher risk of social isolation or
                      loneliness if they experience conditions and circumstances
                      including:
                    </p>
                    <br />
                    <ul className="" style={{ listStyleType: "circle" }}>
                      <li>
                        Death of close family members, especially a spouse
                      </li>
                      <li>Family and friends who do not live nearby</li>
                      <li>Hearing or vision loss</li>
                      <li>Problems with mobility</li>
                      <li>
                        Chronic or serious health conditions that limit their
                        ability to leave home
                      </li>
                      <li>Inability to drive</li>
                      <li>Lower socioeconomic status</li>
                    </ul>
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <h4 className="title mb-10">
                        How Does Companion Care Help Older Adults?
                      </h4>
                      <p className="mb-0">
                        Elderly companion care provides seniors with social
                        interaction and regular camaraderie — ending the cycle
                        of chronic isolation – to reduce the risk of mental and
                        physical health issues.
                      </p>
                      <p className="mb-0">
                        Visiting Angels will provide a bright spot in your older
                        loved one’s day or week that they will eagerly look
                        forward to and anticipate. For example, a compassionate
                        caregiver can be at your loved one’s side conversing
                        over a cup of coffee, playing a board game, completing a
                        puzzle, watching a favorite TV show, and more.
                      </p>
                      <p className="mb-0">Visiting Angels can also help you.</p>
                      <p className="mb-0">
                        Supporting an older loved one while also balancing your
                        own obligations and responsibilities can be an emotional
                        and logistical challenge. Companion home care services
                        can fill in the gaps when you cannot be with them due to
                        personal, professional, and family commitments.
                      </p>
                      <p className="mb-0">
                        Further, Visiting Angels can provide you peace of mind
                        knowing your senior is getting the socialization they
                        need to enhance their attitude and spirit from a
                        dependable and trustworthy caregiver.
                      </p>
                    </div>
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <h4 className="title mb-10">
                        Companion Caregiving vs. Personal Caregiving
                      </h4>
                      <p className="mb-0">
                        When choosing professional care for an elderly loved
                        one, you may decide at-home assistance is a better
                        option than a nursing home or assisted living facility.
                        You will then need to decide between “companion care”
                        and “personal care.”
                      </p>
                      <p className="mb-0">
                        With companion care, duties typically include activities
                        focused on socialization, such as conversation and
                        personal interaction, in and outside the home.
                      </p>
                      <p className="mb-0">
                        Personal care, on the other hand, covers the primary
                        duties of companion care, while also providing physical
                        support with daily activities, including dressing,
                        bathing, toileting, light housekeeping, laundry, grocery
                        shopping, or meal preparation. Personal care can also
                        include non-medical assistance for chronic health
                        conditions such as diabetes or Parkinson's.
                      </p>
                      <p className="mb-0">
                        Most seniors can age in place comfortably with companion
                        caregiving. However, seniors who require advanced
                        support or physical assistance may need personal care to
                        live at home safely.
                      </p>
                    </div>
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <h4 className="title mb-10">
                        Elderly Companion Care - Get Started!
                      </h4>
                      <p className="mb-0">
                        Your local Visiting Angels office makes it easy to get
                        started with home care.
                      </p>
                      <p className="mb-0">
                        A friendly coordinator will schedule a free care
                        consultation with you and your loved one. The
                        coordinator will customize a schedule and home care plan
                        based on your loved one's unique needs and preferences.
                      </p>
                      <p className="mb-0">
                        Visiting Angels will identify the ideal companion
                        through its Select Your Caregiver® program that ensures
                        you find the perfect caregiver for your loved one and
                        the entire family.
                      </p>
                    </div>
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <h4 className="title mb-10">
                        Ready to get started with companion care?
                      </h4>
                      <p className="mb-0">
                        Book for a{" "}
                        <Link to="/booking">LeanOn Care service</Link> today or{" "}
                        <a href="tel:+12403600780">CONTACT US</a> to learn more
                        about our services!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <aside className="sticky-top pb-1">
                    <div className="widget">
                      <ul className="service-menu">
                        <li className="active">
                          <Link to="/home-care-service-detail">
                            <span>Home Care</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/personal-care-service-detail">
                            <span>Personal Care</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/palliative-care-service-detail">
                            <span>Palliative Care</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/respite-care-service-detail">
                            <span>Respite Care</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/alzheimer-care-service-detail">
                            <span>Alzheimer's Care</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/dementia-care-service-detail">
                            <span>Dementia Care</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/transitional-care-service-detail">
                            <span>Transitional Care</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/social-care-service-detail">
                            <span>Social Care</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/end-of-life-care-service-detail">
                            <span>End of Life Care</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="widget">
                      <div className="brochure-bx">
                        <h5 className="title-head">Download</h5>
                        <Link to="#" className="download-link">
                          <img src={pdf} alt="" />
                          <h5 className="title">Download our Brochures</h5>
                          <span>Download</span>
                        </Link>
                        <Link to="#" class="download-link">
                          <img src={doc} alt="" />
                          <h5 className="title">Our Company Details</h5>
                          <span>Download</span>
                        </Link>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </>
    );
  }
}

export default HomeCareDetailServices;
