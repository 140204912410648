import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

// Import Images
import lineCircleBlue from "../../images/shap/line-circle-blue.png";
import squareDotsOrange from "../../images/shap/square-dots-orange.png";
import waveBlue from "../../images/shap/wave-blue.png";
import squareRotate from "../../images/shap/square-rotate.png";
import iconImg from "../../images/icon/homeicon2.png";
import iconImg1 from "../../images/icon/campanionicon1.png";
import iconImg2 from "../../images/icon/alzhemiersicon1.png";
import iconImg3 from "../../images/icon/palliativeicon1.png";
import iconImg4 from "../../images/icon/personalcareicon1.png";
import iconImg5 from "../../images/icon/socialcareicon1.png";
import iconImg6 from "../../images/icon/dementiaicon1.png";
import iconImg7 from "../../images/icon/lifelineicon1.png";
import iconImg8 from "../../images/icon/transistonalicon1.png";

class ServicesSliderSection extends Component{
	render(){
			
		const settings = {
			dots: false,
			infinite: true,
			speed: 1000,
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 591,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};
		
		return(
			<>
				
				<section className="section-area section-sp1 service-wraper">
					<div className="row align-items-center">
						<div className="col-xl-4 col-lg-7 mb-30">	
							<div className="heading-bx">
								<h6 className="title-ext text-secondary">Services</h6>
								{/*<h2 className="title">OUR CLIENTS ARE OUR PURPOSE</h2>*/}
								<p>To develop a culture of inclusivity where people with developmental and intellectual disabilities will have access to advocacy, resources, and the skills needed to be empowered to live on their terms.</p>
							</div>
							<Link to="/services" className="btn btn-secondary btn-lg shadow">All Services</Link>
						</div>
						<div className="col-xl-8 mb-15">	
							<Slider {...settings} className="service-slide slick-arrow-none">
								<div className="slider-item">
									<div className="feature-container feature-bx2 feature1">
										<div className="feature-box-xl mb-30">
											<span className="icon-cell">
											<img className="about-thumb1" src={iconImg} alt=""/>
											</span> 
										</div>
										<div className="icon-content">
											<h3 className="ttr-title">Personal Care/Assistance 
</h3>
											<p></p>
											<Link to="#" className="btn btn-primary light">Read More</Link>
										</div>
									</div>
								</div>
								<div className="slider-item">
									<div className="feature-container feature-bx2 feature2">
										<div className="feature-box-xl mb-20">
											<span className="icon-cell">
												<img className="about-thumb1" src={iconImg1} alt=""/>
											</span> 
										</div>
										<div className="icon-content">
											<h3 className="ttr-title">Companion Care</h3>
											<p></p>
											<Link to="#" className="btn btn-primary light">Read More</Link>
										</div>
									</div>
								</div>
								<div className="slider-item">
									<div className="feature-container feature-bx2 feature3">
										<div className="feature-box-xl mb-20">
											<span className="icon-cell">
												<img className="about-thumb1" src={iconImg2} alt=""/>
											</span> 
										</div>
										<div className="icon-content">
											<h3 className="ttr-title">Home Maker/Cooking</h3>
											<p></p>
											<Link to="#" className="btn btn-primary light">Read More</Link>
										</div>
									</div>
								</div>
								<div className="slider-item">
									<div className="feature-container feature-bx2 feature1">
										<div className="feature-box-xl mb-20">
											<span className="icon-cell">
												<img className="about-thumb1" src={iconImg3} alt=""/>
											</span> 
										</div>
										<div className="icon-content">
											<h3 className="ttr-title"> Skill Building</h3>
											<p></p>
											<Link to="#" className="btn btn-primary light">Read More</Link>
										</div>
									</div>
								</div>
								<div className="slider-item">
									<div className="feature-container feature-bx2 feature1">
										<div className="feature-box-xl mb-20">
											<span className="icon-cell">
												<img className="about-thumb1" src={iconImg4} alt=""/>
											</span> 
										</div>
										<div className="icon-content">
											<h3 className="ttr-title">Community Access</h3>
											<p></p>
											<Link to="#" className="btn btn-primary light">Read More</Link>
										</div>
									</div>
								</div>
								<div className="slider-item">
									<div className="feature-container feature-bx2 feature2">
										<div className="feature-box-xl mb-20">
											<span className="icon-cell">
												<img className="about-thumb1" src={iconImg5} alt=""/>
											</span> 
										</div>
										<div className="icon-content">
											<h3 className="ttr-title">Learning Personal Finance/Budgeting </h3>
											<p></p>
											<Link to="#" className="btn btn-primary light">Read More</Link>
										</div>
									</div>
								</div>
							</Slider>
						</div>	 
					</div>{/*
					<img className="pt-img1 animate-rotate" src={lineCircleBlue} alt=""/>
					<img className="pt-img2 animate2" src={squareDotsOrange} alt=""/>
					<img className="pt-img3 animate-wave" src={waveBlue} alt=""/>
					<img className="pt-img4 animate1" src={squareRotate} alt=""/>*/}
				</section>
				
			</>
		);
	}
}

export default ServicesSliderSection;