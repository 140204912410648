import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { Confirm } from "../elements/confrim";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import pic1 from "../../images/about/pic-1.jpg";
import icon1 from "../../images/icon/icon1.png";
import icon2 from "../../images/icon/icon2.png";
import icon3 from "../../images/icon/icon3.png";
import animateWave from "../../images/shap/wave-blue.png";
import animate2 from "../../images/shap/circle-dots.png";
import animateRotate from "../../images/shap/plus-blue.png";

function ContactUs() {
  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [contact, setContact] = useState("");
  let [interest, setInterest] = useState("");
  let [message, setMessage] = useState("");
  let [popUpDisplay, setPopUpDisplay] = useState("none");

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const handleInterest = (e) => {
    setInterest(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response = await fetch(
        "https://v1.nocodeapi.com/leanoncare/google_sheets/OtCjLgmIfktxqbOA?tabId=contacts",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify([
            [
              new Date().toLocaleString(),
              name,
              email,
              contact,
              interest,
              message,
            ],
          ]),
        }
      );
      await response.json();
      console.log(response);
      setName("");
      setEmail("");
      setInterest("");
      setContact("");
      setMessage("");
      setPopUpDisplay("block");
    } catch (err) {
      console.log(err);
    }
    /*.then((res) => {
					console.log(res)
					setName("");
					setEmail("");
					setInterest("");
					setContact("");
					setMessage("")
				})*/
  };
  return (
    <>
      <div
        className="confirm-container"
        style={{ display: popUpDisplay }}
        onClick={() => setPopUpDisplay("none")}
      >
        <Confirm />
      </div>
      <Header />
      <div className="page-content bg-white">
        <div className="banner-wraper">
          <div
            className="page-banner banner-lg contact-banner"
            style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>Contact Us</h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather feather-home"
                        >
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>{" "}
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Contact Us
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            {/*
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>*/}
          </div>
        </div>
        <section className="">
          <div className="container">
            <div className="contact-wraper">
              <div className="row">
                <div className="col-lg-6 mb-30">
                  <form
                    className="form-wraper contact-form ajax-form"
                    onSubmit={handleSubmit}
                  >
                    <div className="ajax-message"></div>
                    <div className="row">
                      <div className="form-group col-md-12">
                        <input
                          name="name"
                          type="text"
                          required
                          className="form-control"
                          placeholder="Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <input
                          name="email"
                          type="email"
                          required
                          className="form-control"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <input
                          name="phone"
                          type="text"
                          required
                          className="form-control"
                          placeholder="Phone Number"
                          value={contact}
                          onChange={(e) => setContact(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <select
                          className="form-select form-control"
                          placeholder=""
                          value={interest}
                          onChange={handleInterest}
                        >
                          <option value="I am Interested in:(Please Select One)">
                            I am Interested in:(Please Select One)
                          </option>
                          <option value="Home Care">
                            Personal Care/Assistance
                          </option>
                          <option value="Companion Care">Companion Care</option>
                          <option value="Palliative Care">
                            Home Maker/Cooking
                          </option>
                          <option value="Alzheimer's Care">
                            Skill Building
                          </option>
                          <option value="Personal Care">
                            Community Access
                          </option>
                          <option value="Social Care">
                            Learning Personal Finance/Budgeting
                          </option>
                          <option value="I want a Job with LeanOn Care">
                            I want a Job with LeanOn Care
                          </option>
                        </select>
                      </div>
                      <div className="form-group col-md-12">
                        <textarea
                          name="message"
                          required
                          className="form-control"
                          placeholder=""
                          value={message}
                          onChange={handleMessage}
                        />
                      </div>
                      <div className="col-lg-12">
                        <input
                          name="submit"
                          type="submit"
                          value="Submit"
                          className="btn w-100 btn-secondary btn-lg"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-6 mb-30">
                  <div
                    className="contact-info ovpr-dark"
                    style={{ backgroundImage: "url(" + pic1 + ")" }}
                  >
                    <div className="info-inner">
                      <h4 className="title mb-30">
                        Contact LeanOn Care Services Inc
                      </h4>
                      <div className="icon-box">
                        <h6 className="title">
                          <i className="ti-map-alt"></i>Location
                        </h6>
                        <p>158 Front Royal Pike Winchester, VA 22602, USA</p>
                      </div>
                      <div className="icon-box">
                        <h6 className="title">
                          <i className="ti-id-badge"></i>Email &amp; Phone
                        </h6>
                        <a
                          href="mailto:info@leanoncareservices.org"
                          className="text-white"
                        >
                          info@leanoncareservices.org
                        </a>
                        <br />
                        <a
                          href="mailto:lrshacklett@gmail.com"
                          className="text-white"
                        >
                          lrshacklett@gmail.com
                        </a>
                        <br />
                        <a
                          href="tel:+14109377961"
                          style={{ color: "white", fontWeight: "bold" }}
                        >
                          Call: +1 (410) 937-7961
                        </a>
                        <br />
                        <a
                          href="tel:+12403600780"
                          style={{ color: "#fff", fontWeight: "bold" }}
                        >
                          Call: +1 (240) 360-0780
                        </a>
                        <br />
                        <a
                          href="#"
                          style={{ color: "white", fontWeight: "bold" }}
                        >
                          Fax: +1 (866)598-4599
                        </a>
                      </div>
                      <div className="icon-box">
                        {/*
													<h6 className="title"><i className="ti-world"></i>Follow Us</h6>
													<ul className="social-media">
														<li><a rel="noreferrer" target="_blank" href="https://twitter.com/"><i className="fab fa-twitter"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.linkedin.com/"><i className="fab fa-linkedin"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a></li>
													</ul>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-area section-sp1">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx4 feature4">
                  <div className="icon-md feature-icon">
                    <img src={icon1} alt="" />
                  </div>
                  <div className="icon-content">
                    <h5 className="ttr-title">Contact Number</h5>
                    <p>
                      <a href="tel:+14109377961" style={{ color: "#282c34" }}>
                        {" "}
                        Call: +1 (410) 937-7961
                      </a>
                    </p>
                    <p>
                      <a href="tel:+12403600780" style={{ color: "#282c34" }}>
                        Call: +1 (240) 360-0780
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx4 feature3">
                  <div className="icon-md feature-icon">
                    <img src={icon3} alt="" />
                  </div>
                  <div className="icon-content">
                    <h5 className="ttr-title">Email Address</h5>
                    <p>
                      <a
                        href="mailto:info@leanoncareservices.org"
                        style={{ color: "#282c34" }}
                      >
                        info@leanoncareservices.org
                      </a>
                    </p>
                    <p>
                      <a
                        href="mailto:lrshacklett@gmail.com"
                        style={{ color: "#282c34" }}
                      >
                        lrshacklett@gmail.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx4 feature2">
                  <div className="icon-md feature-icon">
                    <img src={icon2} alt="" />
                  </div>
                  <div className="icon-content">
                    <h5 className="ttr-title">Address</h5>
                    <p style={{ color: "#282c34" }}>
                      158 Front Royal Pike Winchester, VA 10010, USA
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default ContactUs;
