import React, { Component } from "react";
import { Link } from "react-router-dom";
import AppointmentSection from "../elements/appointment";
import TestimonialSection from "../elements/testimonial";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import FeatureSection3 from "../elements/feature-section3";
import LatestNewsSection from "../elements/latest-news-slider";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";

import iconImg from "../../images/icon/homeicon2.png";
import iconImg1 from "../../images/icon/campanionicon1.png";
import iconImg2 from "../../images/icon/alzhemiersicon1.png";
import iconImg3 from "../../images/icon/palliativeicon1.png";
import iconImg4 from "../../images/icon/personalcareicon1.png";
import iconImg5 from "../../images/icon/socialcareicon1.png";
import iconImg6 from "../../images/icon/dementiaicon1.png";
import iconImg7 from "../../images/icon/lifelineicon1.png";
import iconImg8 from "../../images/icon/transistonalicon1.png";

class Services extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          <div className="banner-wraper">
            <div
              className="page-banner"
              style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
            >
              <div className="container">
                <div className="page-banner-entry text-center">
                  <h1>Services</h1>
                  <nav aria-label="breadcrumb" className="breadcrumb-row">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="feather feather-home"
                          >
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                          </svg>{" "}
                          Home
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Services
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              {/*
				<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
				<img className="pt-img2 animate2" src={circleDots} alt=""/>
				<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
			  */}
            </div>
          </div>
          <div style={{ padding: "20px 10%", marginBottom: "-100px" }}>
            <h2 className="title mb-10">
              The following mentioned below are our services.
            </h2>
            <div className="icon-content">
              <h3 className="ttr-title">Personal Supports:</h3>
              <p>
                These services provide individuals with the support necessary to
                enable them to live in a community setting. We provide a full
                range of community-based supports including assistance with
                ADLs, general supervision as well as assistance with
                community-based chores and activities. Personal Supports program
                staff teach individual community integration, independent
                living, and safety skills in the community and home setting.
                Personal Supports program staff assist individuals who live in
                their family homes with acquiring, building, or maintaining
                skills necessary to maximize their personal independence.
              </p>
            </div>
            <div className="icon-content">
              <h3 className="ttr-title">Supported Living Services:</h3>
              <p>
                Supported Living services provide individualized support,
                delivered in a personalized manner, to individuals who live in
                homes of their choice, with whom and where they want to live,
                and the type of community activities in which they wish to be
                involved based on their personal resources. Service assists
                individuals in living independently and provides opportunities
                to help the individual help shape the direction of their lives.
                Individuals can choose to live on their own or with a roommate
                and learn how to make everyday decisions, such as what to cook
                for dinner, how to manage money, and engage and participate in
                their community. Supported Living services may be provided in
                the individual’s own house or apartment. Service may include
                coordination, training, support, and/or supervision (as
                indicated in the person-centered plan) related to self-advocacy;
                adaptive skills; community engagement; daily living;
                health-related matters; personal care; protection and oversight;
                social and leisure skills; and transportation and travel
                training.
              </p>
            </div>
          </div>
          <section
            className="section-area section-sp1"
            style={{ marginTop: "100px" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6 mb-30">
                  <div className="feature-container feature-bx2 feature1">
                    <div className="feature-box-xl mb-20">
                      <span className="icon-cell">
                        <img className="about-thumb1" src={iconImg} alt="" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h3 className="ttr-title">Personal Care / Assistance</h3>
                      <p></p>
                      <Link to="#" className="btn btn-primary light">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-30">
                  <div className="feature-container feature-bx2 feature2">
                    <div className="feature-box-xl mb-20">
                      <span className="icon-cell">
                        <img className="about-thumb1" src={iconImg1} alt="" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h3 className="ttr-title">Companion Care</h3>
                      <p></p>
                      <Link to="#" className="btn btn-primary light">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-30">
                  <div className="feature-container feature-bx2 feature3">
                    <div className="feature-box-xl mb-20">
                      <span className="icon-cell">
                        <img className="about-thumb1" src={iconImg3} alt="" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h3 className="ttr-title">Home Maker / Cooking</h3>
                      <p></p>
                      <Link to="#" className="btn btn-primary light">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-30">
                  <div className="feature-container feature-bx2 feature3">
                    <div className="feature-box-xl mb-20">
                      <span className="icon-cell">
                        <img className="about-thumb1" src={iconImg3} alt="" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h3 className="ttr-title">Skill Building</h3>
                      <p></p>
                      <Link to="#" className="btn btn-primary light">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-30">
                  <div className="feature-container feature-bx2 feature2">
                    <div className="feature-box-xl mb-20">
                      <span className="icon-cell">
                        <img className="about-thumb1" src={iconImg2} alt="" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h3 className="ttr-title">Community Access </h3>
                      <p></p>
                      <Link to="#" className="btn btn-primary light">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-30">
                  <div className="feature-container feature-bx2 feature3">
                    <div className="feature-box-xl mb-20">
                      <span className="icon-cell">
                        <img className="about-thumb1" src={iconImg4} alt="" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h3 className="ttr-title">
                        Learning Personal Finance / Budgeting
                      </h3>
                      <p></p>
                      <Link to="#" className="btn btn-primary light">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*
          <TestimonialSection />
          <FeatureSection3 />
          <LatestNewsSection />
          */}
          <AppointmentSection />
        </div>

        <Footer />
      </>
    );
  }
}

export default Services;
