import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Confirm } from "../elements/confrim";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import pic1 from "../../images/about/pic-1.jpg";

function Booking() {
  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [contact, setContact] = useState("");
  let [interest, setInterest] = useState("");
  let [message, setMessage] = useState("");
  let [contactPreference, setContactPreference] = useState("");
  let [freeInHomeCareConsultation, setFreeInHomeCareConsultation] =
    useState("");
  let [zipCodeWhereCareIsNeeded, setZipCodeWhereCareIsNeeded] = useState("");
  let [preferedContactTime, setPreferedContactTime] = useState("");
  let [preferedStartTime, setPreferedStartTime] = useState("");
  let [zipCode, setZipCode] = useState("");
  let [state, setState] = useState("");
  let [city, setCity] = useState("");
  let [address, setAddress] = useState("");
  let [popUpDisplay, setPopUpDisplay] = useState("none");

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const handleInterest = (e) => {
    setInterest(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response = await fetch(
        "https://v1.nocodeapi.com/leanoncare/google_sheets/OtCjLgmIfktxqbOA?tabId=bookings",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify([
            [
              new Date().toLocaleString(),
              name,
              contact,
              email,
              interest,
              contactPreference,
              address,
              city,
              state,
              zipCode,
              preferedStartTime,
              preferedContactTime,
              zipCodeWhereCareIsNeeded,
              freeInHomeCareConsultation,
              message,
            ],
          ]),
        }
      );
      await response.json();
      console.log([
        new Date().toLocaleString(),
        name,
        contact,
        email,
        interest,
        contactPreference,
        address,
        city,
        state,
        zipCode,
        preferedStartTime,
        preferedContactTime,
        zipCodeWhereCareIsNeeded,
        freeInHomeCareConsultation,
        message,
      ]);
      console.log(response);
      setName("");
      setEmail("");
      setInterest("");
      setContact("");
      setMessage("");
      setAddress("");
      setCity("");
      setState("");
      setContactPreference("");
      setFreeInHomeCareConsultation("");
      setPreferedContactTime("");
      setPreferedStartTime("");
      setZipCodeWhereCareIsNeeded("");
      setZipCode("");
      setPopUpDisplay("block");
    } catch (err) {
      console.log(err);
    }
    /*.then((res) => {
					console.log(res)
					setName("");
					setEmail("");
					setInterest("");
					setContact("");
					setMessage("")
				})*/
  };
  return (
    <>
      <div
        className="confirm-container"
        style={{ display: popUpDisplay }}
        onClick={() => setPopUpDisplay("none")}
      >
        <Confirm />
      </div>
      <Header />
      <div className="page-content bg-white">
        <div className="banner-wraper">
          <div
            className="page-banner"
            style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>Booking</h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather feather-home"
                        >
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>{" "}
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Booking a Care Service
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            {/*
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>*/}
          </div>
        </div>
        <section className="section-area section-sp2 appointment-wraper">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 mb-30">
                <div
                  className="contact-info ovpr-dark"
                  style={{ backgroundImage: "url(" + pic1 + ")" }}
                >
                  <div className="info-inner">
                    <h4 className="title mb-30">
                      Contact LeanOn Care Services Inc
                    </h4>
                    <div className="icon-box">
                      <h6 className="title">
                        <i className="ti-map-alt"></i>Location
                      </h6>
                      <p>158 Front Royal Pike Winchester, VA 22602, USA</p>
                    </div>
                    <div className="icon-box">
                      <h6 className="title">
                        <i className="ti-id-badge"></i>Email &amp; Phone
                      </h6>
                      <a
                        href="mailto:info@leanoncareservices.org"
                        className="text-white"
                      >
                        info@leanoncareservices.org
                      </a>
                      <br />
                      <a
                        href="mailto:lrshacklett@gmail.com"
                        className="text-white"
                      >
                        lrshacklett@gmail.com
                      </a>
                      <br />
                      <a
                        href="tel:+14109377961"
                        style={{ color: "white", fontWeight: "bold" }}
                      >
                        Call: +1 (410) 937-7961
                      </a>
                      <br />
                      <a
                        href="tel:+12403600780"
                        style={{ color: "#fff", fontWeight: "bold" }}
                      >
                        Call: +1 (240) 360-0780
                      </a>
                      <br />
                      <a
                        href="#"
                        style={{ color: "white", fontWeight: "bold" }}
                      >
                        Fax: +1 (866)598-4599
                      </a>
                    </div>
                    <div className="icon-box">
                      {/*
												<h6 className="title"><i className="ti-world"></i>Follow Us</h6>
													<ul className="social-media">
														<li><a rel="noreferrer" target="_blank" href="https://twitter.com/"><i className="fab fa-twitter"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.linkedin.com/"><i className="fab fa-linkedin"></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a></li>
													</ul>*/}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 col-md-6">
                <div className="appointment-form form-wraper">
                  <h3 className="title">Book an Appointment With Us</h3>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <select
                        className="form-select form-control"
                        placeholder=""
                        value={interest}
                        onChange={handleInterest}
                      >
                        <option value="I am Interested in:(Please Select One)">
                          I am Interested in:(Please Select One)
                        </option>
                        <option value="Home Care">
                          Personal Care/Assistance
                        </option>
                        <option value="Companion Care">Companion Care</option>
                        <option value="Palliative Care">
                          Home Maker/Cooking
                        </option>
                        <option value="Alzheimer's Care">Skill Building</option>
                        <option value="Personal Care">Community Access</option>
                        <option value="Social Care">
                          Learning Personal Finance/Budgeting
                        </option>
                        <option value="I want a Job with LeanOn Care">
                          I want a Job with LeanOn Care
                        </option>
                      </select>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Name"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="phone"
                        className="form-control"
                        placeholder="Phone Numbers"
                        value={contact}
                        onChange={(e) => {
                          setContact(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <select
                        className="form-select form-control"
                        value={contactPreference}
                        onChange={(e) => {
                          setContactPreference(e.target.value);
                        }}
                      >
                        <option selected>You Contact Preference</option>
                        <option value="Email">Email</option>
                        <option value="Phone">Phone</option>
                        <option value="Text">Text</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Address"
                        value={address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="City"
                        value={city}
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="State"
                        value={state}
                        onChange={(e) => {
                          setState(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Zip Code"
                        value={zipCode}
                        onChange={(e) => {
                          setZipCode(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <select
                        className="form-select form-control"
                        value={preferedStartTime}
                        onChange={(e) => {
                          setPreferedStartTime(e.target.value);
                        }}
                      >
                        <option selected>
                          When will you like services to begin?(Please select
                          one)
                        </option>
                        <option value="Immidiateley">Immidiateley</option>
                        <option value="Within two weeks">
                          Within Two Weeks
                        </option>
                        <option value="Within four weeks">
                          Within Four Weeks
                        </option>
                        <option value="Within eight weeks">
                          Within Eight Weeks
                        </option>
                      </select>
                    </div>
                    <div className="form-group">
                      <select
                        className="form-select form-control"
                        value={preferedContactTime}
                        onChange={(e) => {
                          setPreferedContactTime(e.target.value);
                        }}
                      >
                        <option selected>Best to Contact During</option>
                        <option value="Day">Day</option>
                        <option value="Evening">Evening</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="ZIP Code where care is needed"
                        value={zipCodeWhereCareIsNeeded}
                        onChange={(e) => {
                          setZipCodeWhereCareIsNeeded(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <select
                        className="form-select form-control"
                        value={freeInHomeCareConsultation}
                        onChange={(e) => {
                          setFreeInHomeCareConsultation(e.target.value);
                        }}
                      >
                        <option selected>
                          Do you have an interest in receiving a Free In Home
                          Care Consultation?
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <textarea
                        name="message"
                        required
                        className="form-control"
                        placeholder=""
                        value={message}
                        onChange={handleMessage}
                      />
                    </div>
                    <div className="col-lg-12">
                      <input
                        name="submit"
                        type="submit"
                        value="Submit"
                        className="btn w-100 btn-secondary btn-lg"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
}

export default Booking;
