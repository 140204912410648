import React, { useState } from "react";
import { Confirm } from "./confrim";
import { Link } from "react-router-dom";

// Images
import lineBg from "../../images/appointment/line-bg.png";
import appMobile from "../../images/we-are-hiring-design-template-464aabfc96818f497a51d4c58ee68006_screen.jpg";
import appWomen from "../../images/appointment/women.png";
import appMapPin from "../../images/appointment/map-pin.png";
import appSetting from "../../images/appointment/setting.png";
import appCheck from "../../images/appointment/check.png";
import appChat from "../../images/we-are-hiring-design-template-464aabfc96818f497a51d4c58ee68006_screen.jpg";
import ptImg1 from "../../images/shap/trangle-orange.png";
import ptImg2 from "../../images/shap/wave-orange.png";
import ptImg3 from "../../images/shap/wave-blue.png";
import ptImg4 from "../../images/shap/circle-orange.png";

function AppointmentSection() {
  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [contact, setContact] = useState("");
  let [interest, setInterest] = useState("");
  let [date, setDate] = useState("");
  let [popUpDisplay, setPopUpDisplay] = useState("none");

  const handleInterest = (e) => {
    setInterest(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response = await fetch(
        "https://v1.nocodeapi.com/leanoncare/google_sheets/OtCjLgmIfktxqbOA?tabId=express-bookings",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify([
            [new Date().toLocaleString(), name, email, contact, interest, date],
          ]),
        }
      );
      await response.json();
      setName("");
      setEmail("");
      setInterest("");
      setContact("");
      setDate("");
      setPopUpDisplay("block");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div
        className="confirm-container"
        style={{ display: popUpDisplay }}
        onClick={() => setPopUpDisplay("none")}
      >
        <Confirm />
      </div>
      <section className="section-area account-wraper1">
        <div className="container-fluid">
          <div
            className="appointment-inner section-sp2"
            style={{
              backgroundImage: "url(" + lineBg + ")",
              backgroundRepeat: " no-repeat",
              backgroundPosition: "20px 140px",
            }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-xl-7 col-lg-6 col-md-6"
                  style={{ marginBottom: "50px" }}
                >
                  <div className="appointment-thumb">
                    <img src={appMobile} alt="" />
                    <div className="images-group">
                      {/*<img className="img1" src={appWomen} alt=""/>
												<img className="img2" src={appMapPin} alt=""/>
												<img className="img3" src={appSetting} alt=""/>
												<img className="img4" src={appCheck} alt=""/>
												<img className="img5" src={appChat} alt=""/>*/}
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-md-6">
                  <div className="appointment-form form-wraper">
                    <div className="icon-box">
                      <h6 className="title" style={{ marginBottom: "10px" }}>
                        Contact us today and join our team.
                      </h6>
                      <a
                        href="mailto:info@leanoncareservices.com"
                        className="text-black"
                      >
                        info@leanoncareservices.com
                      </a>
                      <br />
                      <a href="tel:+12403600780" className="text-black">
                        Call: +1 (240) 360-0780
                      </a>
                      <br />
                      <a href="#" className="text-black">
                        Fax: +1 (866)598-4599
                      </a>
                      <br />
                      <br />
                      <div className="icon-content">
                        <Link to="/hiring" className="btn btn-primary light">
                          Read More
                        </Link>
                      </div>
                    </div>
                    {/*<form onSubmit={handleSubmit}>
											<div className="form-group">
												<select className="form-select form-control" placeholder="" value={interest} onChange={handleInterest
													}>
														<option value="I am Interested in:(Please Select One)">I am Interested in:(Please Select One)</option>
														<option value="Home Care">Home Care</option>
														<option value="Companion Care">Companion Care</option>
														<option value="Palliative Care">Palliative Care</option>
														<option value="Alzheimer's Care">Alzheimer's Care</option>
														<option value="Personal Care">Personal Care</option>
														<option value="Social Care">Social Care</option>
														<option value="Dementia Care">Dementia Care</option>
														<option value="End of Life Care">End of Life Care</option>
														<option value="Transition Care">Transition Care</option>
														<option value="Respite Care">Respite Care</option>
														<option value="I want a Job with LeanOn Care">I want a Job with LeanOn Care</option>
													</select>
												</div>
												<div className="form-group">
													<input type="text" className="form-control" placeholder="Your Name" value={name} onChange={(e) => {
														setName(e.target.value)
													}}/>
												</div>
												<div className="form-group">
													<input type="email" className="form-control" placeholder="Your Email" value={email} onChange={(e) => {
														setEmail(e.target.value)
													}}/>
												</div>
												<div className="form-group">
													<input type="number" className="form-control" placeholder="Phone Numbers" value={contact} onChange={(e) => {
														setContact(e.target.value)
													}}/>
												</div>
												<div className="form-group">
													<input type="date" className="form-control" value={date} onChange={(e) => {
														setDate(e.target.value)
													}}/>
												</div>
												<div className="col-lg-12">
													<input name="submit" type="submit" value="Submit" className="btn w-100 btn-secondary btn-lg" />
												</div>
											</form>*/}
                  </div>
                </div>
              </div>
            </div>{" "}
            {/*
							<img className="pt-img1 animate1" src={ptImg1} alt=""/>
							<img className="pt-img2 animate-wave" src={ptImg2} alt=""/>
							<img className="pt-img3 animate-wave" src={ptImg3} alt=""/>
							<img className="pt-img4 animate2" src={ptImg4} alt=""/>*/}
          </div>
        </div>
      </section>
    </>
  );
}

export default AppointmentSection;
