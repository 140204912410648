import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import AppointmentSection from "../elements/appointment";
// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
//import bnrImg1 from "../../images/homecare images/nurse-helping-senior-man-768.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
//import servicesPic1 from "../../images/services/pic1.jpg";
import servicesPic1 from "../../images/companioncare images/Companion-Care-for-Seniors-in-Naperville-IL.jpg";
import pdf from "../../images/icon/pdf.png";
import doc from "../../images/icon/doc.png";

function HiringPage() {
  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [contact, setContact] = useState("");
  let [interest, setInterest] = useState("");
  let [date, setDate] = useState("");
  let [cv, setCV] = useState("");
  let [permit, setPermit] = useState("");
  let [highestQualification, setHighestQualification] = useState("");
  let [popUpDisplay, setPopUpDisplay] = useState("none");

  const handleInterest = (e) => {
    setInterest(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response = await fetch(
        "https://v1.nocodeapi.com/leanoncare/google_sheets/OtCjLgmIfktxqbOA?tabId=express-bookings",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify([
            [new Date().toLocaleString(), name, email, contact, interest, date],
          ]),
        }
      );
      await response.json();
      setName("");
      setEmail("");
      setInterest("");
      setContact("");
      setDate("");
      setHighestQualification("");
      setPopUpDisplay("block");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Header />
      <AppointmentSection />
      <div
        className="column-1 padd-employement-form"
        style={{ marginBottom: "50px" }}
      >
        <div className="container ">
          <div className="head-text mb-30">
            <h2 className="title mb-15" style={{ fontSize: "20px" }}>
              Job Summary:
            </h2>
            <p className="mb-0">This position is with our DDA division</p>
            <br />
            <p className="mb-0">
              A direct Support Professional is one who facilitates services and
              opportunities for individuals with intellectual and developmental
              disabilities to live, work, and access their community by
              promoting independence, self-advocacy, and decision-making. The
              DSP may work in a home, job site, or community location based on
              the needs of those supported. This position can be both part-time
              and full-time. Hours can vary depending on the needs of the
              person(s) supported.
            </p>
          </div>
          <div className="head-text mb-30">
            <h2 className="title mb-15" style={{ fontSize: "20px" }}>
              Essential duties include:
            </h2>
            <ul className="" style={{ listStyleType: "circle" }}>
              <li>
                • Assist individuals with personal care and daily living skills
                (e.g., personal hygiene, health, toileting, eating,
                lifting/transfer).
              </li>
              <li>
                • Teaching Skills that build on individuals’ strengths and
                capabilities through establishing and accomplishing personally
                driven goals.
              </li>
              <li>
                • Assist individuals with daily living and household management
                (e.g., meal prep, laundry, cleaning, decorating).
              </li>
              <li>
                • Assist individuals with becoming active in the community of
                their choice, this may include, transportation needs (banking,
                medical appointments, social or recreational settings),
                developing relationships within the community to build
                friendships and resources.
              </li>
              <li>
                • Assisting with or dispensing medication or other prescribed
                medical treatments as necessary.
              </li>
              <li>
                • Assist individuals to act as their own advocates or connect
                individuals with advocates when there are barriers to their
                service needs.
              </li>
            </ul>
          </div>
          <div className="head-text mb-30">
            <h2 className="title mb-15" style={{ fontSize: "16px" }}>
              You are a good fit if you have the following experience,
              education, and skills:
            </h2>
            <ul className="" style={{ listStyleType: "circle" }}>
              <li>
                • 18 years of age with a high school diploma or GED equivalent.
              </li>
              <li>
                • A valid driving license with a good driving record and a safe
                vehicle for transporting people receiving services. Must have
                car insurance.
              </li>
              <li>
                • Knowledge of computer-based software such as MS Word, Outlook,
                & Excel.
              </li>
              <li>• A passion to care for others.</li>
            </ul>
          </div>
          <div className="head-text mb-30">
            <h2 className="title mb-15" style={{ fontSize: "16px" }}>
              Special Conditions of Employment:
            </h2>
            <ul className="" style={{ listStyleType: "circle" }}>
              <li>
                • Attends, completes and passes all required training, initially
                and ongoing.
              </li>
              <li>
                • Complies with all agency guidelines, procedures, and
                practices.
              </li>
              <li>
                • The employee is occasionally required to assist with
                lifting/transferring.
              </li>
            </ul>
            <p className="mb-0">
              Lean On Care Services is a drug-free employer. All offers of
              employment will be contingent on a successful background check.
              Lean On Care Services is an Equal Opportunity Employer.
            </p>
            <p className="mb-0">Job Type: Part-time</p>
            <p className="mb-0">Pay: $16.00 - $18.00 per hour</p>
            <h2 className="title mb-15" style={{ fontSize: "16px" }}>
              Schedule:
            </h2>
            <ul className="" style={{ listStyleType: "circle" }}>
              <li>• 12-hour shift</li>
              <li>• 8-hour shift</li>
              <li>• 4-hour shift</li>
            </ul>
            <h2 className="title mb-15" style={{ fontSize: "16px" }}>
              Experience:
            </h2>
            <ul className="" style={{ listStyleType: "circle" }}>
              <li>• Caregiving: 2 years (Preferred).</li>
            </ul>
            <h2 className="title mb-15" style={{ fontSize: "16px" }}>
              License/Certification:
            </h2>
            <ul className="" style={{ listStyleType: "circle" }}>
              <li>• CPR/ First Aid Certification (Preferred).</li>
              <li>• Driver's License (Required).</li>
              <li>• Med Tech Certification (Preferred).</li>
            </ul>
          </div>
        </div>
      </div>
      {/*
        <div className="column-1 employ-layout">
          <div className="appointment-form form-wraper padd-employement-form">
            <div className="head-text mb-30">
              <h2 className="title mb-15" style={{ fontSize: "16px" }}>
                Apply Now and Join our Team
              </h2>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  list="interest"
                  className="form-select form-control"
                  placeholder="Please Pick an Interest"
                  value={interest}
                  onChange={handleInterest}
                  id="select-chioce"
                />
                <datalist id="interest">
                  <option value="Personal Care/Assistance Job" />
                  <option value="Companion Care Job" />
                  <option value="Home Maker/Cooking Job" />
                  <option value="Skill Building Job" />
                  <option value="Community Access Job" />
                  <option value="Learning Personal Finance/Budgeting Job" />
                </datalist>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your Name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Your Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Phone Numbers"
                  value={contact}
                  onChange={(e) => {
                    setContact(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  value={highestQualification}
                  onChange={(e) => {
                    setHighestQualification(e.target.value);
                  }}
                  placeholder="Highest Qualification"
                />
              </div>
              <div className="form-group">
                <input
                  type="date"
                  className="form-control"
                  value={date}
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label for="permit">Upload License or Permit</label>
                <input
                  type="file"
                  className="form-control"
                  value={permit}
                  onChange={(e) => {
                    setPermit(e.target.value);
                  }}
                  name="permit"
                />
              </div>
              <div className="form-group">
                <label for="cv">Upload CV</label>
                <input
                  type="file"
                  className="form-control"
                  value={cv}
                  onChange={(e) => {
                    setCV(e.target.value);
                  }}
                  name="cv"
                />
              </div>
              <div className="col-lg-12">
                <input
                  name="submit"
                  type="submit"
                  value="Submit"
                  className="btn w-100 btn-secondary btn-lg"
                />
              </div>
            </form>
          </div>
        </div>*/}
      <Footer />
    </>
  );
}

export default HiringPage;
